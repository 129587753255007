<template>
  <div class="scan-delivery-result-container">
    <div class="space"></div>
    <div class="card">
      <template v-if="goodList.length > 0">

        <div class="card-line" style="margin-top: 20px">

          <cell-v3 :hide-icon="true">
            <div class="main-title" slot="title">
              待核销商品
            </div>
            <div class="scan-button" @click="hide = !hide">
              订单详情
              <img
                  v-if="hide"
                  :src="require('@/static/image/icons/arrowUp.png')"
              />
              <img v-else :src="require('@/static/image/icons/arrowDown.png')"/>
            </div>
          </cell-v3>
        </div>

        <div style="border-bottom: 1px solid #ebebeb;" class="goods-list" v-show="!hide">
          <div
              class="goods"
              v-for="(goodItem, goodIndex) in goodList"
              :key="goodIndex"
          >
            <GoodsResultV4
                :img-url="goodItem.cover"
                :number="goodItem.total"
                :goodsId="goodItem.goods_id"
                :title="goodItem.title"
                :verificationStatus="goodItem.verification_status"
                :status="goodItem.dog_status"
                :status-text="goodItem.status_text"
                @checkSendGoods="checkSendGoods"
            />
          </div>
        </div>
      </template>

      <div class="card-line" style="margin-top: 20px" v-if="doneGoodList.length > 0">

        <cell-v3 :hide-icon="true">
          <div class="main-title" slot="title">
            已核销商品
          </div>
        </cell-v3>
      </div>

      <div style="border-bottom: 1px solid #ebebeb;" class="goods-list" v-show="doneGoodList.length > 0">
        <div
            class="goods"
            v-for="(goodItem, goodIndex) in doneGoodList"
            :key="goodIndex"
        >
          <GoodsResultV4
              :img-url="goodItem.cover"
              :number="goodItem.total"
              :goodsId="goodItem.goods_id"
              :title="goodItem.title"
              :verificationStatus="goodItem.verification_status"
              :status="goodItem.dog_status"
              :status-text="goodItem.status_text"
              :is-done="true"
              :done-time="goodItem.finish_time"
          />
        </div>
      </div>

      <div class="card-line" style="margin-bottom: 20px;margin-top: 20px">
        <cell-v3 :hide-icon="true">
          <!--<div class="main-title" slot="title">
              发货点:{{ pickInfo.store_name }}
          </div>-->
          <div class="main-title" slot="title">
            用户信息
          </div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">订单编码:</div>
          <div class="values">{{ pickInfo.flow_up_code }}</div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">联 系 人 :</div>
          <div class="values">{{ userInfo.real_name }}</div>
        </cell-v3>
      </div>
      <div
          class="card-line"
          style="border-bottom: 1px solid #ebebeb; padding-bottom: 15px"
      >
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">联系电话:</div>
          <div class="values">{{ userInfo.real_mobile }}</div>
        </cell-v3>
      </div>
      <div class="card-line" style="margin-bottom: 20px">
        <cell-v3 :hide-icon="true">
          <div class="main-title" slot="title">
            自提点: {{ point.site_name }}
          </div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">核 销 人 ：</div>
          <div class="values">{{ point.real_name }}</div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">核销时间:</div>
          <div class="values">{{ point.time }}</div>
        </cell-v3>
      </div>
      <div class="card-line">
        <cell-v3 :hide-icon="true">
          <div class="title" slot="title">订单备注:</div>
          <div class="values">{{ remark }}</div>
        </cell-v3>
      </div>

    </div>
    <div class="button-container" @click="makeSure">
      <div class="button">
        <div class="button-inner">
          {{ showName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CellV3 from '../../template/community/cell/cellV3'
import GoodsResultV4 from '../../template/community/goods/GoodsResultV4'

export default {
  name: 'scan-user-result',
  components: {GoodsResultV4, CellV3},
  data() {
    document.title = '用户订单详情'
    return {
      doId: 0,
      code: '0',
      hide: false,
      orderInfo: [],
      pickInfo: [],
      userInfo: [],
      point: [],
      goodList: [],
      doneGoodList: [],
      sendGoodList: [],
      delivery: [],
      remark: '无',
      statusName: {
        0: '货物未到自提点',
        1: '确认发货',
        2: '客户已收货',
      },
      showName: '货物未到自提点',
      isLoading: false
    }
  },
  watch: {
    sendGoodList(newV) {
      this.getShowName()
    }
  },
  created() {
    if (typeof this.$route.query.id != 'undefined') {
      this.doId = parseInt(this.$route.query.id)
    }
    if (typeof this.$route.query.code != 'undefined') {
      this.code = this.$route.query.code
    }
    this.getOrderInfo()
  },
  methods: {
    gotHome() {
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({type: 'navigateBack'})
      } else {
        this.$router.push({path: 'self-extract-home'})
      }
    },
    checkSendGoods(is_checked, goods) {
      if (is_checked) {
        let has = false
        this.sendGoodList.map((item, index) => {
          if (item.goods_id == goods.goods_id && item.spec_ids == goods.spec_ids) {
            has = true
          }
        })
        if (!has) {
          this.sendGoodList.push(goods)
        }
      } else {
        let deleteIndex = 0
        this.sendGoodList.map((item, index) => {
          if (item.goods_id == goods.goods_id && item.spec_ids == goods.spec_ids) {
            deleteIndex = index
          }
        })
        this.sendGoodList.splice(deleteIndex, 1)
      }

    },
    getShowName() {
      this.showName = this.sendGoodList.length + '种商品已选中'
    },
    async getOrderInfo() {
      try {
        let params = {
          id: this.doId,
          code: this.code,
          from_type: 2, //2 用户
        }
        //this.isHasMore=false;
        let query = await this.$api.community.points.pointOrderInfo(params)
        this.orderInfo = query.data

        this.pickInfo = query.data.pickInfo
        this.userInfo = query.data.userInfo
        this.point = query.data.point
        this.goodList = query.data.goodList
        this.doneGoodList = query.data.doneGoodList
        if (this.goodList.length > 0) {
          this.goodList.forEach((item, index) => {
            if (item.verification_status == 0 && item.dog_status == 3) {
              this.sendGoodList.push({goods_id: item.goods_id, spec_ids: item.spec_ids})
            }
          })
        }

        this.delivery = query.data.delivery
        this.remark = query.data.remark

        let site_point_status = this.orderInfo.point.site_point_status
        this.showName = this.statusName[site_point_status]
      } catch (error) {
        this.$toast.fail(error.message)
      }
    },

    async makeSure() {

      if (this.sendGoodList.length == 0) {
        this.$toast.fail('请选中要核销的商品')
        return
      }

      if (this.orderInfo.point.site_point_status == 2) {
        this.$toast.fail('该订单已出库')
        return true
      }
      if (this.orderInfo.point.site_point_status == 0) {
        //判断当前选中的商品中是否有已经到达自提点的商品，如果没有，则显示无法核销
        let hasGoods = 0
        this.goodList.forEach(item => {
          if (item.verification_status == 0 && item.dog_status == 3) {
            hasGoods++
          }
        })

        if (hasGoods == 0) {
          this.$toast.fail('选中的商品还未到达自提点')
          return true
        }

      }
      try {
        if (this.isLoading == true) {
          return
        }
        this.isLoading = true
        this.showName = '数据处理中....'
        let params = {
          id: this.orderInfo.point.id,
          sendGoodList: this.sendGoodList
        }

        console.log(JSON.stringify(params))

        let query = await this.$api.community.points.makeSureSend(params)
        let self = this
        this.$dialog({
          title: '温馨提示',
          content: '货物出库成功',
          closeBtn: false, //显式右上角关闭按钮
          noCancelBtn: true,
          onOkBtn() {
            self.$dialog.closed()
          },
          closeCallback() {
            self.$platform.wxsdk.wxRoute({type: 'navigateBack'})
          }
        })
        this.showName = '货物出库成功'
        // this.orderInfo.point.site_point_status = 2
        this.getOrderInfo()
        this.isLoading = false
        this.$forceUpdate()
      } catch (error) {
        this.isLoading = false
        this.showName = '货物出库失败'
        this.$toast.fail(error.message)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.scan-delivery-result-container {
  width: 100%;
  min-height: 100vh;
  background: #f6f6f6;
  position: relative;

  .space {
    height: 5px;
  }

  .search-container {
    display: flex;
    justify-content: center;

    .search {
      width: 345px;
      height: 32px;
    }
  }

  .card {
    background: #fff;
    margin: 15px;
    padding: 15px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-bottom: 19px;

    .main-title {
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }

    .order-status {
      color: #1c741d;
      font-size: 16px;
      font-weight: bold;
    }

    .title,
    .values {
      color: #333333;
      font-size: 14px;
    }

    .card-line {
      margin-bottom: 9px;

      .scan-button {
        color: #f46a17;
        font-size: 14px;

        img {
          width: 14px;
          height: 14px;
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      .actions {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        color: #f46a17;

        img {
          margin-right: 5px;
          width: 14px;
          height: 14px;
        }
      }
    }

    .bottom-border {
      padding: 0 0 15px 0;
      border-bottom: #ebebeb solid 1px;
      margin-bottom: 20px;
    }

    .goods-list {
      width: 100%;
      border-top: #ebebeb solid 1px;
      padding-bottom: 15px;
      margin-top: 15px;

      .goods {
        margin-top: 15px;
      }
    }

    .money {
      margin-top: 15px;
      font-size: 16px;
      color: #c10000;
      font-weight: bold;
    }
  }
}

.button-container {
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  .button {
    flex: 1;
    height: 44px;
    font-size: 14px;
    background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
}
</style>
