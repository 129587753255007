<template>
    <div class="goods-v4-container">
        <div class="goods-cover">
            <img :src="imgUrl"/>
        </div>
        <div class="goods-info">
            <div class="goods-title">{{title}}</div>
            <div class="number">
                x{{number}}
            </div>

            <template v-if="isDone">
                <div class="status-text" v-if="statusText">已于 {{doneTime}} 核销</div>
            </template>
            <template v-else>
                <div class="status-text" v-if="statusText">{{statusText}}</div>
            </template>
        </div>
        <div v-if="!isDone">
            <nut-checkbox :disabled="(verificationStatus==0 && status == 3)?false:true"
                          v-model="goodsIdVal" :checked="status == 3" @change="checkboxChange">
            </nut-checkbox>

        </div>

    </div>
</template>

<script>
  export default {
    name: 'GoodsResultV4',

    props: {
      imgUrl: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      number: {
        type: [String, Number],
        default: 1
      },
      verificationStatus: {
        type: [String, Number],
        default: 0
      },
      goodsId: {
        type: [String, Number],
        default: 1
      },
      sepcIds: {
        type: [String, Number],
        default: ''
      },
      status: {
        type: [String, Number],
        default: 0
      },
      statusText: {
        type: String,
        default: ''
      },
      isDone: {
        type: Boolean,
        default: false
      },
      doneTime: {
        type: String,
        default: ''
      }
    },
    created () {
      console.log(this.$props.goodsId)
      this.goodsIdVal = this.$props.goodsId
    },
    data () {
      return {
        goodsIdVal: 0
      }
    },
    methods: {
      checkboxChange (checked) {
        this.$emit('checkSendGoods', checked, {goods_id: this.$props.goodsId, spec_ids: this.$props.sepcIds})
      }
    }
  }
</script>

<style scoped lang="scss">
    .goods-v4-container {
        width: 100%;
        display: flex;

        .goods-cover {
            width: 56px;
            height: 56px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-radius: 4px;
            margin-right: 9px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .goods-info {
            flex: 1;

            .goods-title {
                width: 100%;
                line-height: 16px;
                font-size: 14px;
                color: #333333;
            }

            .number {
                display: flex;
                align-items: flex-end;
                color: #939393;
                font-size: 14px;
            }

            .status-text {
                color: #108ee9;
                font-size: 12px;
                margin-top: 5px;
            }
        }
    }

    /deep/ .nut-checkbox input:checked {
        background-color: #f46a17;
    }
</style>